import React from "react";
import SEO from "../../components/seo";
import useProfilePic from "../../hooks/useProfilePic";
import ProfilePage from "../../layouts/ProfilePage";

const Claire = () => {
	const { claire: pic } = useProfilePic();
	return (
		<>
			<SEO
				title="Our Team of Specialists"
				keywords={[`Gastroenterologist`, `Dr Claire Gordon`]}
				description="About Dr Claire Gordon"
			/>
			<ProfilePage
				title="Claire Gordon - Gastroenterologist"
				name="Dr Claire Gordon"
				img={pic.childImageSharp.fixed}
				quote=""
				specialty="Luminal Gastroenterology, Inflammatory bowel disease"
				education={[
					"Undergraduate: University of Western Australia",
					"Postgraduate GI Training: Royal North Shore, Sydney, NSW",
					"Postgraduate GI Training: Liverpool Hospital, Sydney, NSW",
					"Fellowship: Fiona Stanley Hospital, Perth, Western Australia",
					"Fellowship: Royal Devon & Exeter Hospital, UK",
				]}
				description={[
					"Dr Claire Gordon is a gastroenterologist with a subspecialty interest in luminal gastroenterology, particularly inflammatory bowel disease. She is interested in the management of complex inflammatory bowel disease, aiming to achieve mucosal healing and optimise patients’ quality of life.",
					"She is a member of the European Crohn’s and Colitis Organisation and the International Bowel Ultrasound Group. She has further interests in coeliac disease, functional gut disorders, nutrition and bowel cancer screening, including dye-spray chromendoscopy, as well as general adult gastroenterology."
				]}
				honours={[
					"MBBS (Hons) 2010",
				]}
				background="Dr Gordon grew up in Perth and graduated from the University of Western Australia in 2010 with a MBBS (Hons). She trained in gastroenterology at Royal North Shore and Liverpool Hospitals in Sydney. She subsequently undertook two fellowships in inflammatory bowel disease; a clinical fellowship at Fiona Stanley Hospital and then a clinical and research fellowship as part of the IBD Pharmacogenetics Research Group at the Royal Devon and Exeter Hospital in the United Kingdom."
				appointments={[
					"Private appointment - Hollywood Private Hospital, Perth",
					"Public appointment - Sir Charles Gairdner Hospital, Perth",
					"Public appointment - Osborne Park Hospital (ASI Open Access Endoscopy)",
				]}
				memberships={[
					"European Crohn’s & Colitis Organisation",
					"International Bowel Ultrasound Group",
					"Australian Health Practitioner Regulation Agency (AHPRA)",
					"The Royal Australasian College of Physicians (RACP)",
				]}
				personal={[
					"I grew up in Perth where I love the relaxed beach lifestyle. I enjoy travel, reading and scuba diving.",
				]}
				publications={[
					{
						publicationTitle: "Root cause analysis to identify missed opportunities for the diagnosis of colorectal cancer in inflammatory bowel disease",
						authors: "Gordon C, Chee, Hamilton B, Chanchlani N, Heerasing NM, Hendy P, Lin S, Wesley E, Daniels I, Goodhand JR, Kennedy NA , Ahmad T",
					},
					{
						publicationTitle: "Outcomes of a GP outreach programme to offer colonoscopic surveillance for IBD patients being managed in primary care",
						authors: "Windak H, Cairnes V, Chanchlani N, Chee D, Hamilton B , Heerasing N, Hendy P, Lim S, Kennedy NA, Goodhand J, Ahmad T, Gordon C",
					},
					{
						publicationTitle: "Positivity thresholds of total infliximab and adalimumab anti-drug antibody assays and impact in clinical practice",
						authors: "Nice R, Chanchlani N, Green H, Lin S, Gordon C, Chee D, Hamilton B, Bewshea C, Goodhand J, Ahmad T, McDonald T, Perry M, Kennedy NA",
					},
					{
						publicationTitle: "Comparison of outcomes of patients with acute severe ulcerative colitis treated with biosimilar Inflectra compared with originator infliximab",
						authors: "Gordon C, Thin L",
					},
					{
						publicationTitle: "Evaluation of concordance between liver stiffness, AST to platelet ratio index (APRI) and clinical assessment of hepatic fibrosis in pre-treatment patients with chronic hepatitis C",
						authors: "Gordon C, Chu E, Schramko J, Chang P, Jones B, O’Farrell R, Park G, Sandanayake N, V Pattullo",
					},
				]}
			/>
		</>
	);
};

export default Claire;
